export const useWsCart = () => {
  async function getCart(email, entityId, establishmentId, cartId) {
    const getCartEndpoint = "/api/cart/getCart";
    const bodyCartId = {
      email,
      entityId,
      establishmentId
    };
    if (cartId)
      bodyCartId.cartId = cartId;
    try {
      return await $fetch(getCartEndpoint, {
        method: "POST",
        body: bodyCartId
      });
    } catch (err) {
      return err;
    }
  }
  async function addProductToDataBaseCart(product, cartId, entityId, establishmentId) {
    return await $fetch("/api/cart/addProduct", {
      method: "POST",
      body: JSON.stringify({
        cartId,
        product,
        entityId,
        establishmentId
      })
    });
  }
  async function updateProductToDataBaseCart(product, cartId, entityId, establishmentId) {
    return await $fetch("/api/cart/updateProduct", {
      method: "POST",
      body: JSON.stringify({
        cartId,
        productId: product.id,
        quantity: product.quantity,
        entityId,
        establishmentId
      })
    });
  }
  async function deleteProductToDataBaseCart(product, cartId, entityId, establishmentId) {
    return await $fetch("/api/cart/deleteProduct", {
      method: "POST",
      body: JSON.stringify({
        cartId,
        productId: product.id,
        entityId,
        establishmentId
      })
    });
  }
  async function killCart(cartId, entityId, establishmentId) {
    return await $fetch("/api/cart/killCart", {
      method: "POST",
      body: JSON.stringify({
        cartId,
        entityId,
        establishmentId
      })
    });
  }
  return {
    getCart,
    addProductToDataBaseCart,
    updateProductToDataBaseCart,
    deleteProductToDataBaseCart,
    killCart
  };
};
