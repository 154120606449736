const toFixedComma = (number, fractionDigits) => {
  return number.toFixed(fractionDigits).replace(".", ",");
};
const toFixedDot = (number, fractionDigits) => {
  return number.toFixed(fractionDigits).replace(",", ".");
};
export const useFormatNumber = () => {
  return {
    toFixedComma,
    toFixedDot
  };
};
