export const ProductTypes = {
  pa: {
    id: 1,
    name: "Pi\xE8ce auto"
  },
  shop: {
    id: 2,
    name: "Boutique"
  },
  consigne: {
    id: 3,
    name: "Consigne"
  },
  pneu: {
    id: 5,
    name: "Pneumatique"
  }
};
