import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useCookie } from "#app";
import { useFormatNumber } from "./useFormatNumber.mjs";
import { useShoppingCartStore } from "../stores/useShoppingCartStore.mjs";
import { useWsCart } from "./webservice/useWsCart.mjs";
import { useDebug } from "./useDebug.mjs";
import { ProductTypes } from "../types/cart.mjs";
let isAddCartPopupOpen = ref(false);
let isCartHt = ref(false);
let isConsigneIncluded = ref(true);
let currentDevise = ref("\u20AC");
let userEmail = ref("");
const { error } = useDebug();
const { toFixedComma } = useFormatNumber();
const { getCart, addProductToDataBaseCart, updateProductToDataBaseCart, deleteProductToDataBaseCart, killCart } = useWsCart();
export const useCart = () => {
  const cartStorage = useShoppingCartStore();
  const { shoppingCart } = storeToRefs(cartStorage);
  const cart = shoppingCart;
  function sendInitializationError() {
    throw new Error("The shopping cart must be initialized. please use initializeCart(customerEmail: string, isHt: boolean = false, isCartConsigneIncluded: boolean = true, isNewCart?: boolean, devise?: string) for initialize the shopping cart.");
  }
  ;
  async function initializeCart(customerEmail, entityId, establishmentId, isHt = false, isCartConsigneIncluded = true, isNewCart, devise) {
    try {
      const currentDate = /* @__PURE__ */ new Date();
      const expiresCookieDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
      const cartIdCookie = useCookie("cartId-graphi-toolbox", { expires: expiresCookieDate });
      const cartId = !isNewCart ? cartIdCookie.value : null;
      const data = await getCart(customerEmail, entityId, establishmentId, cartId);
      if (!data)
        throw data;
      const shoppingCart2 = data;
      cart.value = shoppingCart2;
      cartIdCookie.value = shoppingCart2.id;
      isCartHt.value = isHt;
      if (devise)
        currentDevise.value = devise;
      isConsigneIncluded.value = isCartConsigneIncluded;
      userEmail.value = customerEmail;
    } catch (err) {
      console.error(err);
    }
  }
  ;
  function isCartInitialized() {
    return cart?.value.id && cart?.value.idEntity && cart?.value.idEtablissement && cart?.value.products ? true : false;
  }
  ;
  const totalTTCCart = computed(() => {
    let total = 0;
    if (!cart?.value?.products)
      return total;
    cart.value.products.forEach((product) => {
      const consignePrice = getConsignePriceTtc(product);
      total += (product.price.unitPriceTTC + consignePrice) * product.quantity * product.conditionnement;
    });
    return total;
  });
  const totalHTCart = computed(() => {
    let total = 0;
    if (!cart?.value?.products)
      return total;
    cart.value.products.forEach((product) => {
      const consignePrice = getConsignePriceHt(product);
      total += (product.price.unitPriceHT + consignePrice) * product.quantity * product.conditionnement;
    });
    return total;
  });
  const totalVatAmount = computed(() => {
    let totalVatAmount2 = 0;
    if (!cart?.value?.products)
      return totalVatAmount2;
    cart.value.products.forEach((product) => {
      const consigneVatAmount = getVatConsigneAmount(product);
      totalVatAmount2 += (product.price.unitVATAmount + consigneVatAmount) * product.quantity * product.conditionnement;
    });
    return totalVatAmount2;
  });
  const numberOfItems = computed(() => {
    if (!cart?.value?.products)
      return 0;
    const nbOfProducts = getProductInCart();
    return nbOfProducts.length;
  });
  const numberOfQuantity = computed(() => {
    let total = 0;
    if (!cart?.value?.products)
      return total;
    const nbOfProducts = getProductInCart();
    nbOfProducts.forEach((product) => {
      total += product.quantity;
    });
    return total;
  });
  const weight = computed(() => {
    let total = 0;
    cart.value.products.forEach((product) => {
      total += product.weightPerUnit * product.quantity * product.conditionnement;
    });
    return total;
  });
  async function addProductToCart(product, quantityToAdd) {
    if (!isCartInitialized())
      sendInitializationError();
    try {
      if (!isProductInCart(product)) {
        const data = await addProductToDataBaseCart(product, cart.value.id, cart.value.idEntity, cart.value.idEtablissement);
        if (hasConsigne(product) && isValidConsigne(product) && !isConsigneIncluded.value)
          addConsigneToCart(product);
        product.id = data;
        cart.value.products.push(product);
        isAddCartPopupOpen.value = true;
        return true;
      }
      ;
      const currentCartItemIndex = getProductIndex(product);
      const currentProduct = cart.value.products[currentCartItemIndex];
      currentProduct.quantity += quantityToAdd;
      updateProductToDataBaseCart(currentProduct, cart.value.id, cart.value.idEntity, cart.value.idEtablissement);
      isAddCartPopupOpen.value = true;
      return true;
    } catch (e) {
      error("[addProductToCart]", e);
      return false;
    }
  }
  ;
  async function addProductsToCart(products) {
    if (!products.length)
      return;
    for (let index = 0; index < products.length; index++) {
      await addProductToCart(products[index], products[index].quantity);
    }
  }
  async function addConsigneToCart(product) {
    try {
      const consigne = JSON.parse(JSON.stringify(product));
      setConsigne(consigne, product);
      setProduct(product);
      const data = await addProductToDataBaseCart(consigne, cart.value.id, cart.value.idEntity, cart.value.idEtablissement);
      if (!data)
        return false;
      consigne.id = data;
      cart.value.products.push(consigne);
      return true;
    } catch (e) {
      error("[addConsigneToCart]", e);
      return false;
    }
  }
  ;
  const updateConsigneQuantity = (product, removeAll) => {
    const consignes = getConsigneInCart();
    const linkedConsigne = consignes.filter((consigne) => consigne.uniqueReference === product.consigneLinkedUnicReference || consigne.consigne.consigneReference === product.consigneLinkedUnicReference)[0];
    if (removeAll) {
      const consigneIndex = getProductIndex(linkedConsigne);
      cart.value.products.splice(consigneIndex, 1);
      return;
    }
    linkedConsigne.quantity = product.quantity;
  };
  const getProductInCart = () => {
    return cart.value.products.filter((product) => product.productType.id !== ProductTypes.consigne.id);
  };
  const getConsigneInCart = () => {
    return cart.value.products.filter((product) => product.productType.id === ProductTypes.consigne.id);
  };
  function removeProductFromCart(product, quantityToRemove = 1, allQuantityToRemove = false, showConfirm = true) {
    if (!isCartInitialized())
      sendInitializationError();
    try {
      if (!isProductInCart(product))
        return true;
      const currentCartItemIndex = getProductIndex(product);
      const currentProduct = cart.value.products[currentCartItemIndex];
      if (allQuantityToRemove) {
        if (showConfirm) {
          const confirm = window.confirm("Voulez-vous vraiment supprimer cet article ?");
          if (!confirm)
            return false;
        }
        cart.value.products.splice(currentCartItemIndex, 1);
        deleteProductToDataBaseCart(currentProduct, cart.value.id, cart.value.idEntity, cart.value.idEtablissement);
        updateConsigneQuantity(currentProduct, true);
        return true;
      }
      ;
      currentProduct.quantity -= quantityToRemove;
      updateProductToDataBaseCart(currentProduct, cart.value.id, cart.value.idEntity, cart.value.idEtablissement);
      updateConsigneQuantity(currentProduct);
      return true;
    } catch (e) {
      error("[removeProductFromCart]", e);
      return false;
    }
    ;
  }
  ;
  function clearCart() {
    const confirm = window.confirm("Voulez-vous vraiment vider votre panier ?");
    if (!isCartInitialized())
      sendInitializationError();
    if (!confirm)
      return false;
    cart.value.products = [];
    const entityId = cart.value.idEntity;
    const establishmentId = cart.value.idEtablissement;
    killCart(cart.value.id, cart.value.idEntity, cart.value.idEtablissement);
    initializeCart(userEmail.value, entityId, establishmentId, isCartHt.value, isConsigneIncluded.value, true, currentDevise.value);
    return true;
  }
  ;
  function isProductInCart(product) {
    return getProductIndex(product) !== -1;
  }
  ;
  function getProductIndex(product) {
    return cart.value.products.findIndex((item) => item.uniqueReference === product.uniqueReference);
  }
  ;
  const currentTypeOfPrice = computed(() => {
    return isCartHt.value ? "HT" : "TTC";
  });
  const isShoppingCartHt = computed(() => isCartHt.value);
  const getProductPriceLabel = (product, isTypeOfPrice = false) => {
    const consignePrice = isConsigneIncluded.value ? getConsignePrice(product) : 0;
    const productPriceHt = `${toFixedComma(product.price.unitPriceHT + consignePrice, 2)} ${currentDevise.value}`;
    const productPriceTtc = `${toFixedComma(product.price.unitPriceTTC + consignePrice, 2)} ${currentDevise.value}`;
    const productPriceToShow = isCartHt.value ? productPriceHt : productPriceTtc;
    return isTypeOfPrice ? `${productPriceToShow} ${currentTypeOfPrice.value}` : productPriceToShow;
  };
  const setConsigne = (consigne, product) => {
    consigne.id = 0;
    consigne.name = `Consigne - ${product.name}`;
    consigne.link = "";
    consigne.price.isPromoActive = false;
    consigne.price.unitPriceBeforePromoHT = product.consigne.unitConsignePriceHT;
    consigne.price.unitPriceBeforePromoTTC = product.consigne.unitConsignePriceTTC;
    consigne.price.unitPriceHT = product.consigne.unitConsignePriceHT;
    consigne.price.unitPriceTTC = product.consigne.unitConsignePriceTTC;
    consigne.price.unitPurchasePriceHT = 0;
    consigne.price.unitVATAmount = product.consigne.unitConsignePriceTTC - product.consigne.unitConsignePriceHT;
    consigne.price.vatPercentage = product.consigne.tauxTva;
    consigne.productType.id = ProductTypes.consigne.id;
    consigne.productType.name = ProductTypes.consigne.name;
    consigne.consigne.unitConsignePriceHT = 0;
    consigne.consigne.unitConsignePriceTTC = 0;
    consigne.consigne.tauxTva = 0;
    consigne.consigneLinkedUnicReference = product.uniqueReference;
    consigne.quantity = product.quantity;
    consigne.packaging = product.packaging;
    consigne.soldBy = product.conditionnement;
    consigne.weightPerUnit = 0;
    consigne.isFresh = false;
    consigne.isFreeDelivery = false;
  };
  const setProduct = (product) => {
    product.consigne.tauxTva = 0;
    product.consigne.unitConsignePriceHT = 0;
    product.consigne.unitConsignePriceTTC = 0;
  };
  const getProductPrice = (product) => {
    const consignePrice = isConsigneIncluded.value ? getConsignePrice(product) : 0;
    return isCartHt.value ? product.price.unitPriceHT + consignePrice : product.price.unitPriceTTC + consignePrice;
  };
  const getProductPriceHt = (product) => {
    const consignePrice = isConsigneIncluded.value ? getConsignePriceHt(product) : 0;
    return product.price.unitPriceHT + consignePrice;
  };
  const getProductPriceTtc = (product) => {
    const consignePrice = isConsigneIncluded.value ? getConsignePriceTtc(product) : 0;
    return product.price.unitPriceHT + consignePrice;
  };
  const getTotalPrice = computed(() => {
    return isCartHt.value ? totalHTCart.value : totalTTCCart.value;
  });
  const getConsignePriceHt = (product) => {
    let total = 0;
    if (isConsigneIncluded.value) {
      total = product.consigne.unitConsignePriceHT;
    } else {
      total = product.price.unitPriceHT;
    }
    return total > 0 ? total : 0;
  };
  const getConsignePriceTtc = (product) => {
    let total = 0;
    if (isConsigneIncluded.value) {
      total = product.consigne.unitConsignePriceTTC;
    } else {
      total = product.price.unitPriceTTC;
    }
    return total > 0 ? total : 0;
  };
  const getConsignePrice = (product) => {
    return isCartHt.value ? getConsignePriceHt(product) : getConsignePriceTtc(product);
  };
  const getVatConsigneAmount = (product) => {
    let total = 0;
    total = getConsignePriceTtc(product) - getConsignePriceHt(product);
    return total > 0 ? total : 0;
  };
  const isConsigne = (product) => {
    return product.productType.id === ProductTypes.consigne.id;
  };
  const hasConsigne = (product) => {
    return product.consigne.consigneReference && product.consigne.consigneReference !== "";
  };
  const isValidConsigne = (product) => {
    return product.consigne.unitConsignePriceHT > 0 && hasConsigne(product);
  };
  return {
    addProductToCart,
    removeProductFromCart,
    clearCart,
    initializeCart,
    numberOfItems,
    numberOfQuantity,
    totalTTCCart,
    totalHTCart,
    totalVatAmount,
    currentTypeOfPrice,
    cart,
    currentDevise,
    getProductPriceLabel,
    getProductPrice,
    getTotalPrice,
    getConsignePrice,
    isAddCartPopupOpen,
    isShoppingCartHt,
    isConsigneIncluded,
    isConsigne,
    updateConsigneQuantity,
    getProductPriceHt,
    getProductPriceTtc,
    addProductsToCart,
    weight
  };
};
